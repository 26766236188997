import React, { useRef } from 'react'
import styled from 'styled-components'

import { Content, Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import LazyImage from 'components/shared/LazyImage'

import useOnScreen from 'hooks/useOnScreen'

import type Image from 'types/image'

type Props = {
  title: string
  subtitle: string
  mainImage: Image
  smallImage: Image
  description: string
}

const Section = styled.section`
  padding: 2rem 0 4rem;
  ${({ theme }) => theme.media.lg.max} {
    padding: 2rem 0;
  }
  ${({ theme }) => theme.media.sm.max} {
    padding: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.media.md.max} {
    flex-direction: column-reverse;
  }
`

const MainImage = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  margin: 0 30px 0 0;
  ${({ theme }) => theme.media.xl.min} {
    max-height: initial;
  }
  ${({ theme }) => theme.media.md.max} {
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    max-width: none;
  }
`

const ContentWrapper = styled.div<{ active: boolean }>`
  width: 100%;
  max-width: 820px;
  ${Heading}:first-child {
    position: relative;
    padding-bottom: 1.5rem;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: ${({ theme }) => theme.colors.primary};
      transition: 2s cubic-bezier(0.39, 0.575, 0.565, 1);
      transform-origin: 0 50%;
      transform: ${({ active }) => (active ? `scaleX(1)` : `scaleX(0)`)};
      transition-delay: 0.5s;
    }
    ${({ theme }) => theme.media.md.max} {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }
  ${({ theme }) => theme.media.xl.min} {
    padding-top: 60px;
  }
`

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${({ theme }) => theme.media.xxl.min} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const SmallImage = styled.div`
  width: 100%;
  margin: 0 0 20px 0;
  ${({ theme }) => theme.media.xxl.min} {
    min-width: 280px;
    max-width: 330px;
    margin: 0 20px 0 0;
  }
`

const StyledContent = styled(Content)`
  width: 100%;
  ${({ theme }) => theme.media.xxl.min} {
    max-width: 420px;
    padding-top: 70px;
  }
`

const HomepageAbout: React.FC<Props> = ({
  title,
  subtitle,
  mainImage,
  smallImage,
  description,
}) => {
  const contentRef = useRef(null)
  const visible = useOnScreen(contentRef, '0px')
  return (
    <Section>
      <Container slim>
        <Wrapper>
          <MainImage>
            <LazyImage src={mainImage.src} alt={mainImage.alt ?? ''} fill />
          </MainImage>
          <ContentWrapper ref={contentRef} active={visible}>
            <Heading
              as="h3"
              size={50}
              sizeDiff={0.7}
              weight={700}
              margin="2rem"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <Heading
              as="div"
              size={42}
              sizeDiff={0.8}
              weight={400}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
            <ContentInner>
              <SmallImage>
                <LazyImage src={smallImage.src} alt={smallImage.alt ?? ''} />
              </SmallImage>
              <StyledContent
                as="article"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </ContentInner>
          </ContentWrapper>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default HomepageAbout

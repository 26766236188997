import React from 'react'
import styled from 'styled-components'

import LazyImage from 'components/shared/LazyImage'

import type Image from 'types/image'
import type { Stat } from './SingleStat'
import SingleStat from './SingleStat'

type Props = {
  image: Image
  keyVisual: Image
  statistics: Stat[]
}

const Section = styled.section`
  position: relative;
  width: 100%;
  ${({ theme }) => theme.media.md.min} {
    min-height: min-content;
    max-height: 960px;
    height: 100vh;
  }
  ${({ theme }) => theme.media.lg.min} {
    margin-top: -3rem;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;

  ${({ theme }) => theme.media.md.max} {
    margin-top: 1rem;
    flex-direction: column;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-top: 7rem;
  }
`

const MainImage = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 130px;
  ${({ theme }) => theme.media.md.min} {
    margin-bottom: 0;
    height: 100%;
    ${({ theme }) => theme.media.lg.max} {
      width: 50%;
    }
  }
`

const ContentWrapper = styled.div`
  width: 100%;
`

const StatsWrapper = styled.div`
  margin: 2rem 0 0;
  padding: 0 1.5rem;
  ${({ theme }) => theme.media.md.min} {
    margin: auto;
    max-width: max-content;
    padding: 0 0 0 200px;
    ${({ theme }) => theme.media.xl.max} {
      padding: 0 0 0 150px;
    }
  }
`

const KeyVisual = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -200px;
  width: 400px;
  height: 400px;
  ${({ theme }) => theme.media.xl.max} {
    right: -140px;
    width: 280px;
    height: 280px;
  }
  ${({ theme }) => theme.media.md.max} {
    top: auto;
    right: 50%;
    transform: translateX(50%);
    bottom: -140px;
  }
`

const Statistics: React.FC<Props> = ({ image, keyVisual, statistics }) => {
  return (
    <Section>
      <InnerWrapper>
        <MainImage>
          <LazyImage src={image.src} alt={image.alt ?? ''} fill />
          <KeyVisual>
            <LazyImage src={keyVisual.src} alt={keyVisual.alt ?? ''} />
          </KeyVisual>
        </MainImage>
        <ContentWrapper>
          <StatsWrapper>
            {statistics.map(({ icon, value, label }) => (
              <SingleStat key={label} icon={icon} value={value} label={label} />
            ))}
          </StatsWrapper>
        </ContentWrapper>
      </InnerWrapper>
    </Section>
  )
}

export default Statistics

import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

// import { Swiper, SwiperSlide } from 'swiper/react'

import { Text } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Icon from 'components/shared/Icon'

import useBreakpoint from 'hooks/useBreakpoint'

import type Image from 'types/image'

import arrowRightIcon from 'assets/icons/arrow-right-orange.svg'

export type HeaderInvestment = {
  name: string
  link: string
  image: Image
  availableFlats?: string
  tag: {
    text: string
    color: string
  }
}

type Props = {
  investments: HeaderInvestment[]
  sticky?: boolean
}

const Wrapper = styled.aside<{ sticky?: boolean }>`
  padding: 1.5rem 0.75rem;
  ${({ theme }) => theme.media.xs.min} {
    padding: 1.5rem;
  }
  ${({ sticky }) =>
    sticky &&
    css`
      display: none;
      ${({ theme }) => theme.media.lg.min} {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        padding: 0;
      }
    `}
`

const InnerWrapper = styled.div<{ sticky?: boolean }>`
  ${({ sticky }) =>
    !sticky &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      grid-column-gap: 10px;
      ${({ theme }) => theme.media.xs.min} {
        grid-column-gap: 20px;
      }
      ${({ theme }) => theme.media.lg.min} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    `}

  ${({ sticky }) =>
    sticky &&
    css`
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 40px 0;
      height: 100%;
      & > a:not(:last-child) {
        margin-bottom: 10px;
      }
    `}
`

const Arrow = styled.div`
  transition: 0.25s;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  ${({ theme }) => theme.media.md.max} {
    bottom: 40px;
  }
`

const ImageInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.6s;
`

const Investment = styled(LocalizedLink)<{ insideStickyList?: boolean }>`
  position: relative;
  display: block;
  height: 180px;
  width: 100%;
  &:hover {
    ${ImageInnerWrapper} {
      transform: scale(1.1);
    }
    ${Arrow} {
      transform: translateX(80%);
    }
  }

  margin-bottom: 70px;

  ${({ theme }) => theme.media.xs.min} {
    height: 220px;
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.media.sm.min} {
    height: 220px;
    margin-bottom: 65px;
  }

  ${({ theme }) => theme.media.md.min} {
    height: 280px;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 38px;
  }

  ${({ insideStickyList }) =>
    insideStickyList &&
    css`
      ${({ theme }) => theme.media.lg.min} {
        max-height: 200px;
        height: 100%;
        min-width: 210px;
      }
    `}
`

const TextWrapper = styled.div<{ insideStickyList?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem 1rem 0.5rem;
  max-width: 135px;

  ${({ theme }) => theme.media.md.max} {
    background: ${({ theme }) => theme.colors.white50};
  }

  ${({ theme }) => theme.media.md.min} {
    border-left: 1px solid ${({ theme }) => theme.colors.white100};
  }

  ${({ theme }) => theme.media.lg.max} {
    max-width: 135px;
  }

  ${({ insideStickyList }) =>
    insideStickyList &&
    css`
      ${({ theme }) => theme.media.lg.min} {
        max-width: 110px;
      }
    `}
`

// const StyledSwiper = styled(Swiper)`
//   overflow: visible;
//   .swiper-slide {
//     width: auto !important;
//   }
//   ${({ theme }) => theme.media.lg.min} {
//     display: none;
//   }
// `

const FlatsCounter = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% + 3px));
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.white100};
  border-left: 1px solid ${({ theme }) => theme.colors.white100};
  border-right: 1px solid ${({ theme }) => theme.colors.white100};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white100};

  ${Text} {
    position: static;

    :first-child {
      margin-right: 10px;
    }
  }

  ${({ theme }) => theme.media.md.min} {
    transform: translateY(100%);
    border-left: 1px solid ${({ theme }) => theme.colors.white100};
  }
`

const Tag = styled.div<{ bgcolor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.35rem 0.5rem;

  ${({ bgcolor }) =>
    bgcolor &&
    css`
      background-color: ${bgcolor};
    `}

  ${({ theme }) => theme.media.lg.min} {
    padding: 0.5rem 1rem;
  }
`

const HeaderInvestments: React.FC<Props> = ({ investments, sticky }) => {
  const { lg } = useBreakpoint()

  const InvestmentsDOM = useMemo(
    () =>
      investments
        .slice(0, lg && sticky ? 3 : 8)
        .map(({ name, link, image, availableFlats, tag }) => (
          <Investment key={name} to={link} insideStickyList={sticky}>
            <ImageWrapper>
              <ImageInnerWrapper>
                <LazyImage src={image.src} alt={image.alt ?? name} fill />
              </ImageInnerWrapper>
            </ImageWrapper>
            {availableFlats && (
              <FlatsCounter>
                <Text size={14} weight={400} themecolor="gray" margin="0">
                  Dostępne mieszkania:
                </Text>
                <Text
                  size={14}
                  weight={400}
                  themecolor="primary"
                  margin="0"
                  dangerouslySetInnerHTML={{ __html: availableFlats }}
                />
              </FlatsCounter>
            )}
            <TextWrapper>
              <Text line="1.2" weight={600}>
                {name}
              </Text>
              <Arrow>
                <Icon src={arrowRightIcon} />
              </Arrow>
            </TextWrapper>
            <Tag bgcolor={tag.color}>
              <Text
                size={lg ? 17 : 14}
                themecolor="white"
                weight={600}
                dangerouslySetInnerHTML={{ __html: tag.text }}
              />
            </Tag>
          </Investment>
        )),
    [lg]
  )

  return (
    <Wrapper sticky={sticky}>
      <InnerWrapper sticky={sticky}> {InvestmentsDOM}</InnerWrapper>
    </Wrapper>
  )
}

export default HeaderInvestments

import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import LazyImage from 'components/shared/LazyImage'

import kvBG from 'assets/icons/dark-shape-kv.svg'

import type Image from 'types/image'

export type CTASlide = {
  title: string
  link: string
  linkText: string
  image: Image
  active?: boolean
}

const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  ${({ theme }) => theme.media.sm.min} {
    margin-left: 0;
    width: 100%;
    min-height: 600px;
    padding: 2rem 3rem;
    display: flex;
    align-items: center;
    ${Button} {
      height: 60px;
    }
  }
`

const SlideBackground = styled.div`
  height: 240px;
  ${({ theme }) => theme.media.sm.min} {
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    ${({ theme }) => theme.media.xl.min} {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        min-width: 650px;
        width: 40%;
        height: 100%;
        z-index: 1;
        background: url(${kvBG}) no-repeat top left;
        background-size: 100%;
      }
    }
  }
`

const StyledHeading = styled(Heading)`
  span {
    background: ${({ theme }) => theme.colors.primary};
    padding: 8px;
    white-space: nowrap;
  }
  ${({ theme }) => theme.media.sm.max} {
    margin-bottom: 1.25rem;
  }
`

const SlideContent = styled(Container)`
  ${({ theme }) => theme.media.sm.max} {
    position: relative;
    padding: 2rem 1rem;
    background: ${({ theme }) => theme.colors.dark};
    & > ${Button} {
      max-width: max-content;
    }
  }
`

const Slide: React.FC<CTASlide> = ({
  title,
  image,
  active = true,
  link,
  linkText,
}) => {
  return (
    <Wrapper key={title} active={active}>
      <SlideBackground>
        <LazyImage src={image.src} alt={image.alt ?? title} fill />
      </SlideBackground>
      <SlideContent slim>
        <StyledHeading
          as="h3"
          weight={700}
          line="1.5"
          size={50}
          sizeDiff={0.8}
          themecolor="white"
          margin="2rem"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Button
          outline
          width="240px"
          color="white"
          white
          as={LocalizedLink}
          to={link}
        >
          {linkText}
        </Button>
      </SlideContent>
    </Wrapper>
  )
}

export default Slide

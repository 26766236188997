/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'

export default function useOnScreen(
  ref: React.MutableRefObject<any>,
  rootMargin: string
) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (ref.current == null || isVisible) return
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { rootMargin }
    )
    observer.observe(ref.current)
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref.current, rootMargin])

  return isVisible
}

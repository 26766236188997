import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Cookies from 'js-cookie'

import Icon from 'components/shared/Icon'
import LazyImage from 'components/shared/LazyImage'

import useOutsideClick from 'hooks/useOutsideClick'

import closeIcon from 'assets/icons/close.svg'

import type Image from 'types/image'

type Props = {
  image: Image
  expires?: number | null
  link?: string | null
}

const Overlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const Wrapper = styled.div<{ pointer: boolean; visible: boolean }>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -35%);
  visibility: hidden;
  opacity: 0;
  transition: 0.35s ease;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%);
    `}
`

const InnerWrapper = styled.div`
  position: relative;
  max-width: 800px;
`

const CloseButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  padding: 6px;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  border: none;
  span {
    transition: 0.2s;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
    & > span {
      filter: brightness(0);
    }
  }
  ${({ theme }) => theme.media.xs.min} {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
`

const IconWrapper = styled.div`
  position: relative;
  width: 95vw;
  max-width: max-content;
  img {
    max-height: 80vh;
  }
  & > div {
    max-width: 800px;
  }
`

const Popup: React.FC<Props> = ({ image, expires, link }) => {
  if (!image.src) return null

  const [visible, setVisible] = useState(false)

  const KEY = image.alt || 'artbud-popup'

  const closePopup = () => {
    Cookies.set(KEY, 'true', { expires: expires || 7 })
    setVisible(false)
  }

  useEffect(() => {
    const isAccepted = Cookies.get(KEY)
    if (!isAccepted && image.src) {
      setVisible(true)
    }
  }, [])

  const popupRef = useRef(null)
  useOutsideClick({ ref: popupRef, handler: closePopup, condition: visible })

  return (
    <Overlay visible={visible}>
      <Wrapper
        pointer={!!link}
        as={Link}
        to={link!}
        ref={popupRef}
        visible={visible}
      >
        <InnerWrapper>
          <IconWrapper>
            <CloseButton onClick={closePopup}>
              <Icon src={closeIcon} alt="" auto />
            </CloseButton>
            <LazyImage src={image.src} alt={KEY} />
          </IconWrapper>
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  )
}

export default Popup

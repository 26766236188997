// eslint-disable

import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'

import Header, { HeaderSlide } from 'components/layout/Headers/Homepage'
import HeaderFilters from 'components/layout/Headers/Homepage/Filters'
import HeaderInvestments, {
  HeaderInvestment,
} from 'components/layout/Headers/Homepage/Investments'
import HeroSection from 'components/layout/Sections/AboutUs/Hero'
import Statistics from 'components/layout/Sections/Homepage/Statistics'
import CarouselCTA from 'components/layout/Sections/Homepage/CarouselCTA'
import AboutCompany from 'components/layout/Sections/Homepage/About'
import SportSection, {
  Partner,
} from 'components/layout/Sections/Homepage/Sport'
import Popup from 'components/shared/Popup'

import useFilters from 'hooks/useFilters'

import type { IndexPageQuery } from 'types/graphql'
import type { InvestmentFilters } from 'types/filters'
import type { FlatSimple } from 'types/flat'
// import type { InvestmentCard } from 'types/investments'
import type { Stat } from 'components/layout/Sections/Homepage/Statistics/SingleStat'
import type { CTASlide } from 'components/layout/Sections/Homepage/CarouselCTA/Slide'
// import Investments from 'components/layout/Sections/Investments/Full'

const IndexPage: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  const PAGE = data?.page?.pageMain
  const PAGE_SEO = data?.page?.seo
  const OFFER = data.IN_SALE?.investments?.nodes
  const FILTERS = data.INVESTMENT_FILTERS.nodes as InvestmentFilters[]
  const ALL_FLATS = data.allFlats.nodes as FlatSimple[]

  if (!PAGE || !PAGE_SEO || !OFFER)
    throw new Error("CMS data didn't load properly")

  const HEADER_SLIDES: HeaderSlide[] = useMemo(
    () =>
      PAGE.mainPageS1Slider?.map((slide) => ({
        image: {
          src: slide?.mainPageS1SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.mainPageS1SliderElementImg?.altText!,
        },
        title: slide?.mainPageS1SliderElementTitle!,
        subtitle: slide?.mainPageS1SliderElementSubtitle!,
        buttonText: slide?.mainPageS1SliderElementCta?.title!,
        buttonLink: slide?.mainPageS1SliderElementCta?.url!,
      })) || [],
    []
  )

  const HEADER_INVESTMENTS: HeaderInvestment[] = useMemo(
    () =>
      OFFER.map((investment) => {
        const allInvestmentFlats = ALL_FLATS.filter(
          (flat) => flat.investment === investment?.title
        )
        const availableInvestmentFlatsNumber = allInvestmentFlats
          .filter((flat) => flat.status === 'available')
          .length.toString()

        return {
          name: investment?.title!,
          link: `/${investment?.slug}`,
          image: {
            src: investment?.investmentFields?.investmentMapImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: investment?.investmentFields?.investmentMapImg?.altText!,
          },
          availableFlats: `${availableInvestmentFlatsNumber}/${allInvestmentFlats.length}`,
          tag: {
            text: investment?.investmentFields?.investmentTagText!,
            color: investment?.investmentFields?.investmentTagColor!,
          },
        }
      }) || [],
    []
  )

  // const INVESTMENTS: InvestmentCard[] = useMemo(
  //   () =>
  //     OFFER.map((investment) => ({
  //       name: investment?.title!,
  //       link: `/${investment?.slug}`,
  //       description: investment?.investmentFields?.investmentTeaser!,
  //       image: {
  //         src: investment?.investmentFields?.investmentMapImg?.localFile
  //           ?.childImageSharp?.gatsbyImageData!,
  //         alt: investment?.investmentFields?.investmentMapImg?.altText!,
  //       },
  //       location: investment?.investmentFields?.investmentDistrict
  //         ? `${investment?.investmentFields?.investmentCity} - ${investment?.investmentFields?.investmentDistrict}`
  //         : investment?.investmentFields?.investmentCity!,
  //       tag: {
  //         text: investment?.investmentFields?.investmentTagText,
  //         color: investment?.investmentFields?.investmentTagColor,
  //       },
  //     })) || [],
  //   []
  // )

  const STATISTICS: Stat[] = useMemo(
    () =>
      PAGE.mainPageS4List?.map((stat) => ({
        icon: stat?.mainPageS4ListElementIcon?.localFile?.publicURL!,
        value: stat?.mainPageS4ListElementTitle!,
        label: stat?.mainPageS4ListElementDescription!,
      })) || [],
    []
  )

  const CAROUSEL_CTA_SLIDES: CTASlide[] = useMemo(
    () =>
      PAGE.mainPageS5Slider?.map((slide) => ({
        image: {
          src: slide?.mainPageS5SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.mainPageS5SliderElementImg?.altText!,
        },
        title: slide?.mainPageS5SliderElementTitle!,
        link: slide?.mainPageS5SliderElementCta?.url!,
        linkText: slide?.mainPageS5SliderElementCta?.title!,
      })) || [],
    []
  )

  const SPORT_PARTNERS: Partner[] = useMemo(
    () =>
      PAGE.mainPageS7List?.map((slide) => ({
        image: {
          src: slide?.mainPageS7ListElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.mainPageS7ListElementImg?.altText!,
        },
      })) || [],
    []
  )

  const [filteredInvestments, { fields, filters, applyFilters, setFilters }] =
    useFilters('investment', { items: FILTERS })

  return (
    <Layout>
      <Popup
        image={{
          src: PAGE.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE.popupImg?.title!,
        }}
        link={PAGE.popupLink}
        expires={PAGE.popupCookie}
      />
      <SEO seo={PAGE_SEO} />
      <Header
        title={PAGE.mainPageS2Title!}
        slides={HEADER_SLIDES}
        investments={HEADER_INVESTMENTS}
      >
        <HeaderInvestments investments={HEADER_INVESTMENTS} />
        <HeaderFilters
          filters={filters}
          includedFilters={fields}
          setFilters={setFilters}
          applyFilters={applyFilters}
          filtersHome
        />
      </Header>
      <main>
        {/* <Investments
          title={PAGE.mainPageS2Title!}
          investments={INVESTMENTS.filter((investment) =>
            filteredInvestments.some((el) => el.investment === investment.name)
          )}
        /> */}
        <HeroSection
          title={PAGE.mainPageS3Title!}
          titleShiftBottom
          background={{
            src: PAGE.mainPageS3TitleBackground?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.mainPageS3TitleBackground?.altText!,
          }}
        />
        <Statistics
          image={{
            src: PAGE.mainPageS4Background?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.mainPageS4Background?.altText!,
          }}
          keyVisual={{
            src: PAGE.mainPageS4Logotyp?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.mainPageS4Logotyp?.altText!,
          }}
          statistics={STATISTICS}
        />
        <CarouselCTA
          title={PAGE.mainPageS5Title!}
          slides={CAROUSEL_CTA_SLIDES}
        />
        <AboutCompany
          title={PAGE.mainPageS6Title!}
          subtitle={PAGE.mainPageS6Subtitle!}
          mainImage={{
            src: PAGE.mainPageS6Background?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.mainPageS6Background?.altText!,
          }}
          smallImage={{
            src: PAGE.mainPageS6List![0]!.mainPageS6ListElementImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: PAGE.mainPageS6List![0]!.mainPageS6ListElementImg?.altText!,
          }}
          description={
            PAGE.mainPageS6List![0]!.mainPageS6ListElementDescription!
          }
        />
        <SportSection
          title={PAGE.mainPageS7Title!}
          button={{
            link: PAGE.mainPageS7Cta?.url!,
            text: PAGE.mainPageS7Cta?.title!,
          }}
          partners={SPORT_PARTNERS}
          background={{
            src: PAGE.mainPageS7Bg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.mainPageS7Bg?.altText!,
          }}
        />
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    INVESTMENT_FILTERS: allFilters {
      nodes {
        investment
        location
        area {
          min
          max
        }
        rooms
        floor
        type
      }
    }
    IN_SALE: wpInvestmentCategory(slug: { eq: "inwestycja-w-sprzedazy" }) {
      investments {
        nodes {
          title
          slug
          investmentFields {
            investmentDistrict
            investmentCity
            investmentTeaser
            investmentTagText
            investmentTagColor
            investmentMapImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
    allFlats: allFlats {
      nodes {
        investment
        status
      }
    }
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/strona-glowna|homepage/" }
    ) {
      seo {
        ...WpSEO
      }
      pageMain {
        popupCookie
        popupLink
        popupImg {
          title
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        mainPageS1Slider {
          mainPageS1SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          mainPageS1SliderElementTitle
          mainPageS1SliderElementSubtitle
          mainPageS1SliderElementCta {
            title
            url
          }
        }
        mainPageS2Title
        mainPageS3Title
        mainPageS3TitleBackground {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        mainPageS4Background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        mainPageS4Logotyp {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        mainPageS4List {
          mainPageS4ListElementTitle
          mainPageS4ListElementDescription
          mainPageS4ListElementIcon {
            localFile {
              publicURL
            }
          }
        }
        mainPageS5Title
        mainPageS5Slider {
          mainPageS5SliderElementTitle
          mainPageS5SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          mainPageS5SliderElementCta {
            title
            url
          }
        }
        mainPageS6Title
        mainPageS6Subtitle
        mainPageS6List {
          mainPageS6ListElementDescription
          mainPageS6ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        mainPageS6Background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        mainPageS7Title
        mainPageS7List {
          mainPageS7ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        mainPageS7Cta {
          url
          title
        }
        mainPageS7Bg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    testImg: file(name: { eq: "bg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`

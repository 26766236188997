/* eslint-disable */

import React from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'
import HeaderInvestments, {
  HeaderInvestment,
} from 'components/layout/Headers/Homepage/Investments'
import useSlider from 'hooks/useSlider'

import { Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import ArrowButton from 'components/shared/Button/Arrow'
import LazyImage from 'components/shared/LazyImage'

import Image from 'types/image'

export type HeaderSlide = {
  image: Image
  title: string
  subtitle?: string
  buttonText?: string
  buttonLink?: string
}

type Props = {
  title: string
  slides: HeaderSlide[]
  investments: HeaderInvestment[]
}

const Wrapper = styled.header`
  position: relative;
  margin-bottom: 6rem;
  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 9rem;
  }
  ${({ theme }) => theme.media.xxl.min} {
    margin-bottom: 5rem;
  }
`

// const InnerWrapper = styled.div`
//   display: none;
//   position: relative;
//   min-height: 480px;
//   max-height: 600px;
//   height: ${({ theme }) => `calc(100vh - ${theme.navigation.mobile})`};
//   ${({ theme }) => theme.media.md.min} {
//     display: block;
//     margin-top: 20px;
//   }
//   ${({ theme }) => theme.media.lg.min} {
//     min-height: 580px;
//     max-height: 900px;
//     height: ${({ theme }) => `calc(100vh - ${theme.navigation.desktop})`};
//   }
// `

// const ImageWrapper = styled.div<{ active: boolean }>`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   user-select: none;
//   opacity: 0;
//   visibility: hidden;
//   transition: 0.6s;
//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 1;
//     background: rgba(0, 0, 0, 0.4);
//   }

//   ${({ active }) =>
//     active &&
//     css`
//       visibility: visible;
//       opacity: 1;
//     `}
// `

// const ContentWrapper = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   padding: 140px 0;
//   z-index: 1;
//   pointer-events: none;
//   & > ${Container} {
//     display: flex;
//     align-items: center;
//   }
// `

// const Content = styled.div<{ active: boolean }>`
//   display: flex;
//   flex-direction: column;
//   max-width: 750px;

//   opacity: 0;
//   visibility: hidden;
//   transition: 0.5s;
//   transform: translate3d(50px, 0, 0);
//   pointer-events: none;

//   ${({ theme }) => theme.media.md.min} {
//     & > ${Button} {
//       height: 60px;
//     }
//   }

//   ${({ active }) =>
//     active &&
//     css`
//       visibility: visible;
//       opacity: 1;
//       pointer-events: all;
//       transform: translate3d(0, 0, 0);
//       transition-delay: 0.8s;
//     `}
// `

// const ControlArrows = styled.div`
//   position: absolute;
//   top: 0;
//   bottom: 40px;
//   left: 0;
//   right: 0;
//   z-index: 3;
//   pointer-events: none;
//   display: flex;
//   align-items: flex-end;
//   ${({ theme }) => theme.media.lg.min} {
//     bottom: 90px;
//   }
// `

const StyledHeading = styled(Heading)`
  margin-top: 3rem;
  padding: 0 3.45rem;
`

const Header: React.FC<Props> = ({ title, slides, investments, children }) => {

  // const { activeSlide, goNext, goPrev } = useSlider(slides)

  return (
    <Wrapper>        
      
    <StyledHeading
    as="h2"
    size={50}
    sizeDiff={0.7}
    weight={500}
    align="center"
    dangerouslySetInnerHTML={{ __html: title }}
  />
      {children}
      {/* <InnerWrapper> 
        {slides.map(
          ({ image, title, subtitle, buttonLink, buttonText }, index) => (
            <React.Fragment key={title + index}>
              <ImageWrapper active={index === activeSlide}>
                <LazyImage src={image.src} alt={image.alt ?? ''} fill />
              </ImageWrapper>
              <ContentWrapper>
                <Container slim fullHeight>
                  <Content active={index === activeSlide}>
                    <Heading
                      as="h1"
                      themecolor="white"
                      margin="0.5rem"
                      line="1"
                      weight={800}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                    {subtitle && (
                      <Heading
                        as="h2"
                        themecolor="white"
                        size={40}
                        sizeDiff={0.7}
                        weight={400}
                        margin="1.5rem"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                      />
                    )}
                    {!!(buttonLink && buttonText) && (
                      <Button
                        width="240px"
                        color="white"
                        as={LocalizedLink}
                        to={buttonLink}
                      >
                        {buttonText}
                      </Button>
                    )}
                  </Content>
                </Container>
              </ContentWrapper>
            </React.Fragment>
          )
        )} }
       </InnerWrapper> */}
        {/* <ControlArrows>
          <Container slim>
            <ArrowButton onClick={goPrev} direction="left" />
            <ArrowButton onClick={goNext} direction="right" />
          </Container>
        </ControlArrows>  */}
        {/* <HeaderInvestments investments={investments} sticky /> */}
    </Wrapper>
  )
}

export default Header

import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from 'gatsby-theme-i18n'

import { Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import LazyImage from 'components/shared/LazyImage'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

import type Image from 'types/image'

import bracketIcon from 'assets/icons/bracket.svg'

export type Partner = {
  image?: Image | null
}

type Props = {
  title: string
  partners: Partner[]
  background: Image
  button: {
    link: string
    text: string
  }
}

const Section = styled.section`
  position: relative;
  padding: 6rem 0;
  margin-top: 4rem;
  background: ${({ theme }) => theme.colors.white50};
  ${({ theme }) => theme.media.xxl.max} {
    margin-top: 0;
    padding: 4rem 0 6rem;
    & > ${Container} {
      max-width: 100%;
      ${({ theme }) => theme.media.xs.max} {
        padding: 0;
        & > ${Heading} {
          padding: 0 1.5rem;
        }
      }
    }
  }
`

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 42, 158, 0.4);
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  padding: 4rem 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.xxl.max} {
    padding: 2rem 0 0;
  }
  ${({ theme }) => theme.media.md.min} {
    & > ${Button} {
      height: 60px;
    }
  }
  ${({ theme }) => theme.media.xs.max} {
    & > ${Button} {
      max-width: calc(100% - 3rem);
    }
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  max-width: 1400px;
`

const PartnerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  margin: 0.75rem;
  & > div {
    max-width: 115px;
    max-height: 110px;
  }
  ${({ theme }) => theme.media.xl.max} {
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    max-height: 90px;
    margin: 0.5rem;
    & > div {
      max-width: 70px;
      max-height: 50px;
    }
  }
`

const BracketLeft = styled.div`
  position: absolute;
  top: -25px;
  left: 0;
  bottom: 0;
  & > span {
    transform: rotate(180deg);
  }
  ${({ theme }) => theme.media.xxl.max} {
    display: none;
  }
`

const BracketRight = styled.div`
  position: absolute;
  top: -25px;
  right: 0;
  bottom: 0;
  ${({ theme }) => theme.media.xxl.max} {
    display: none;
  }
`

const HomepageSport: React.FC<Props> = ({
  title,
  background,
  partners,
  button,
}) => {
  return (
    <Section>
      <Background>
        <LazyImage src={background.src} alt={background.alt ?? ''} fill />
      </Background>
      <Container slim>
        <Heading
          as="h2"
          size={40}
          weight={500}
          margin="0"
          align="center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Wrapper>
          <BracketLeft>
            <Icon src={bracketIcon} />
          </BracketLeft>
          <BracketRight>
            <Icon src={bracketIcon} />
          </BracketRight>
          <InnerWrapper>
            {partners.map(({ image }, index) => (
              <PartnerItem key={index}>
                {image && (
                  <LazyImage
                    src={image.src}
                    alt={image.alt ?? ''}
                    objectFit="contain"
                  />
                )}
              </PartnerItem>
            ))}
          </InnerWrapper>
          <Button width="240px" as={LocalizedLink} to={button.link}>
            {button.text}
          </Button>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default HomepageSport

import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

type Props = {
  Slides: JSX.Element[]
}

const Wrapper = styled.div`
  position: relative;
`

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`

const Dot = styled.button<{ active: boolean }>`
  width: 18px;
  height: 18px;
  margin: 0 5px;
  border: 2px solid #a7a7a7;
  background: transparent;
  border-radius: 50%;
  transition: 0.25s;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      transform: scale(1.2);
    `}
`

const DotsSingleCarousel: React.FC<Props> = ({ Slides }) => {
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const slideTo = (index: number) => {
    swiper?.slideTo(index)
  }

  return (
    <Wrapper>
      <Swiper
        slidesPerView="auto"
        grabCursor
        mousewheel={{ releaseOnEdges: true }}
        onSwiper={(initSwiper: SwiperInstance) => setSwiper(initSwiper)}
        onSlideChange={({ realIndex }) => setActiveIndex(realIndex)}
      >
        {Slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
      {Slides.length > 1 && (
        <Dots className="controls">
          {Slides.map((_, index) => (
            <Dot
              key={index}
              onClick={() => slideTo(index)}
              active={activeIndex === index}
              aria-label={`slide to ${index + 1}`}
            />
          ))}
        </Dots>
      )}
    </Wrapper>
  )
}

export default DotsSingleCarousel

import React from 'react'
import styled from 'styled-components'

import Icon from 'components/shared/Icon'

import { Heading, Text } from 'components/shared/Typography'

export type Stat = {
  icon: string
  value: string
  label: string
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
  &:not(:last-child):before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #dbdbdb;
    width: 100%;
    ${({ theme }) => theme.media.md.min} {
      width: 100vw;
    }
  }
  ${({ theme }) => theme.media.md.min} {
    max-width: 440px;
  }
  & + & {
    margin-top: 1.5rem;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  min-width: 116px;
  min-height: 116px;
  max-height: 116px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white50};
  ${({ theme }) => theme.media.xxl.max} {
    width: 84px;
    min-width: 84px;
    min-height: 84px;
    max-height: 84px;
    padding: 20px;
  }
`

const StatContent = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  ${({ theme }) => theme.media.xxl.max} {
    padding: 0.25rem 0 0 1rem;
    & > ${Heading} {
      font-size: 2.5rem;
    }
    & > ${Text} {
      font-size: 1rem;
    }
  }
`

const SingleStat: React.FC<Stat> = ({ icon, value, label }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon src={icon} alt={icon} />
      </IconWrapper>
      <StatContent>
        <Heading as="span" margin="0" line="1.2" size={60} weight={300}>
          {value}
        </Heading>
        <Text
          as="div"
          size={21}
          themecolor="darkgray"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </StatContent>
    </Wrapper>
  )
}

export default SingleStat

import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'

import Slide, {
  CTASlide,
} from 'components/layout/Sections/Homepage/CarouselCTA/Slide'
import DotsSingleCarousel from 'components/layout/Carousels/DotsSingleCarousel'

import useBreakpoint from 'hooks/useBreakpoint'
import useSlider from 'hooks/useSlider'

type Props = {
  title?: string
  slides: CTASlide[]
}

const Section = styled.section`
  padding: 5rem 0 3rem;
  ${({ theme }) => theme.media.sm.max} {
    padding: 3rem 0 2.5rem;
    & > ${Container} {
      padding: 0;
    }
    .controls {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }
`

const CarouselCTA: React.FC<Props> = ({ title, slides }) => {
  const { sm } = useBreakpoint()
  const { activeSlide } = useSlider(slides)

  const Slides = useMemo(
    () =>
      slides.map((slide, index) => (
        <Slide
          key={slide.title}
          title={slide.title}
          image={slide.image}
          link={slide.link}
          linkText={slide.linkText}
          active={index === activeSlide}
        />
      )),
    [slides, activeSlide, sm]
  )

  if (!title) {
    return <DotsSingleCarousel Slides={Slides} />
  }

  return (
    <Section>
      <Container>
        <Heading
          as="h2"
          size={50}
          sizeDiff={0.7}
          weight={500}
          align="center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <DotsSingleCarousel Slides={Slides} />
      </Container>
    </Section>
  )
}

export default CarouselCTA
